import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../Context/DashboardContext";
import PendingApplication from "./PendingApplication";
import AppliedApplication from "./AppliedApplication";
import { Helmet } from "react-helmet-async";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";
import { GenericDropdownContext } from "../../../Context/GenericDropdownContext";
import { TypeMasterContext } from "../../../Context/TypeMaster";

const Dashboard = () => {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const {setActiveTab} = useContext(UserDetailsContext)
  const { appliedEntryCount, pendingEntryCount } = useContext(DashboardContext);

  const {getGenericDropdownData , getGenericParamsDropdownData} = useContext(GenericDropdownContext)

  const { getRoleType, getGender} = useContext(TypeMasterContext)
  const [submitMessage, setSubmitMessage] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  useEffect(() => {
    setActiveTab(1);
  }, []);

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getGenericDropdownData(access);
      getGenericParamsDropdownData(access);
    } else {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getGenericDropdownData(access);
      getGenericParamsDropdownData(access);
    }
  }, [isAccess]);

  useEffect(() => {
    getRoleType();
    getGender();
  }, []);

  return (
    <>
      <Helmet>
        <title>Careers | Job Vacancy In Mumbai | Fluidscapes</title>
        <meta
          name="description"
          content="Discover exciting job opportunities in Fluidscapes Mumbai and seize your chance to embark on a fulfilling career journey. Join us now!"
        />
        <meta name="Keywords" content="job vacancy in mumbai" />
        <link rel="canonical" href="https://career.fluidscapes.in" />
      </Helmet>

      <div class="nk-block-head nk-block-head-sm pb-0">
        <div class="nk-block-between">
          <div class="nk-block-head-content">
            <h3 class="nk-block-title page-title">Dashboard</h3>
          </div>
        </div>
      </div>

      <div class="nk-block pt-0">
        <div class="row g-gs mb-2">
          <div class="col-lg-6 col-sm-6">
            <div class="card h-100 bg-danger">
              <div class="nk-cmwg nk-cmwg1">
                <div class="card-inner pt-3">
                  <div class="d-flex justify-content-between">
                    <div class="flex-item">
                      <div class="text-white d-flex flex-wrap">
                        <span class="fs-2 me-1">{pendingEntryCount}</span>
                      </div>
                      <h6 class="text-white">Pending Application</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="card h-100 bg-primary">
              <div class="nk-cmwg nk-cmwg1">
                <div class="card-inner pt-3">
                  <div class="d-flex justify-content-between">
                    <div class="flex-item">
                      <div class="text-white d-flex flex-wrap">
                        <span class="fs-2 me-1">{appliedEntryCount}</span>
                      </div>
                      <h6 class="text-white">Applied Application</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <>
              <PendingApplication setSubmitMessage={setSubmitMessage} />

              <AppliedApplication />

              <div>
                {submitMessage && (
                  <div className="example-alerts position-fixed bottom-0 end-0 p-3">
                    <div className="gy-4">
                      <div className="example-alert">
                        <div
                          className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                        >
                          <em
                            className={`icon ni ni-${submitMessage.icon}-circle`}
                          ></em>{" "}
                          <strong>{submitMessage.message}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
