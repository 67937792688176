import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { VARIABLES } from "../Constant";
import { RefreshTokenContext } from "./RefreshTokenContext";
import secureLocalStorage from "react-secure-storage";
export const GenericDropdownContext = createContext(null);

function GenericDropdownContextProvider({ children }) {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);

  const [isGenricDropdownDataLoading, setisGenricDropdownDataLoading] =
    useState(true);
  const [
    isGenricParamsDropdownDataLoading,
    setisGenricParamsDropdownDataLoading,
  ] = useState(true);

  //GENERIC DROPDOWN STATES
  const [departmentDropdownData, setDepartmentDropdownData] = useState([]);
  const [teamDropdownData, setTeamDropdownData] = useState([]);
  const [positionDropdownData, setPositionDropdownData] = useState([]);
  const [seniorityDropdownData, setSeniorityDropdownData] = useState([]);
  const [roleDropdownData, setRoleDropdownData] = useState([]);
  const [formsDropdownData, setFormsDropdownData] = useState([]);
  const [jobOpeningDropdownData, setJobOpeningDropdownData] = useState([]);
  const [workflowDropdownData, setWorkflowDropdownData] = useState([]);
  const [tagCategoryDropdownData, setTagCategoryDropdownData] = useState([]);
  const [typeMasterCategoryDropdownData, setTypeMasterCategoryDropdownData] =
    useState([]);
  const [jobAssignmentDropdownData, setJobAssignmentDropdownData] = useState(
    []
  );
  const [usersDropdownData, setUsersDropdownData] = useState([]);
  const [agencyNameDropdownData, setAgencyNameDropdownData] = useState([]);

  const getGenericDropdownData = async (access) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/generic/",
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      setisGenricDropdownDataLoading(false);
      setDepartmentDropdownData(res.data.department);
      setTeamDropdownData(res.data.team);
      setPositionDropdownData(res.data.position);
      setSeniorityDropdownData(res.data.seniority);
      setRoleDropdownData(res.data.roles);
      setFormsDropdownData(res.data.forms);
      setJobOpeningDropdownData(res.data.job_opening);
      setWorkflowDropdownData(res.data.workflow);
      setTagCategoryDropdownData(res.data.tag_category);
      setTypeMasterCategoryDropdownData(res.data.type_master_category);
      setJobAssignmentDropdownData(res.data.job_assignment);
      setAgencyNameDropdownData(res.data.agency);
    } catch (error) {
      console.log(error);
      setisGenricDropdownDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const getGenericParamsDropdownData = async (access) => {
    let url =
      VARIABLES.url + `/api/generic/?login_types_id=${VARIABLES.career_login}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      setUsersDropdownData(res.data.user);
      setisGenricParamsDropdownDataLoading(false);
    } catch (error) {
      console.log(error);
      setisGenricParamsDropdownDataLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  // useEffect(() => {
  //   if (isAccess) {
  //     let access = secureLocalStorage.getItem("access");
  //     console.log(access);
  //     getGenericDropdownData(access);
  //     getGenericParamsDropdownData(access);
  //   } else {
  //     let access = secureLocalStorage.getItem("access");
  //     console.log(access);
  //     getGenericDropdownData(access);
  //     getGenericParamsDropdownData(access);
  //   }
  // }, [isAccess]);

  return (
    <div>
      <GenericDropdownContext.Provider
        value={{
          isGenricDropdownDataLoading,
          isGenricParamsDropdownDataLoading,
          getGenericDropdownData,
          getGenericParamsDropdownData,

          departmentDropdownData,
          teamDropdownData,
          positionDropdownData,
          seniorityDropdownData,
          roleDropdownData,
          formsDropdownData,
          jobOpeningDropdownData,
          workflowDropdownData,
          tagCategoryDropdownData,
          typeMasterCategoryDropdownData,
          jobAssignmentDropdownData,
          agencyNameDropdownData,

          usersDropdownData,
        }}
      >
        {children}
      </GenericDropdownContext.Provider>
    </div>
  );
}

export default GenericDropdownContextProvider;
